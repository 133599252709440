import React, { useEffect } from 'react';
import { BidType, DraftItemSpecified, GameState } from '@magicyard/wreckless-game/src/Types';
import { CardGrid } from '../../../components/card/CardGrid';
import { NAMES_TO_COLORS } from '@magicyard/wreckless-game/src/Game';
import './CardsSummary.css';

export const CardsSummary = ({ G, playerID }: { G: GameState; playerID: string }) => {
  const currentlyBidCards = G.draftingSets[G.draftingIndex]?.reduce<
    { bid: BidType & { colorName: keyof typeof NAMES_TO_COLORS }; items: Array<DraftItemSpecified> } | undefined
  >((acc, draftingSet, setIndex) => {
    const existingBid = G.bidsByRoundBySetIndex?.[G.draftingIndex]?.[setIndex];
    if (existingBid?.playerID === playerID) {
      acc = { bid: { ...existingBid, colorName: G.players[existingBid.playerID].colorName }, items: draftingSet };
    }

    return acc;
  }, undefined);

  return <CardGrid hand={G.hands[playerID]} className={'cards-summary_grid'} bidItems={currentlyBidCards} />;
};
