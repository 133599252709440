import React, { CSSProperties } from 'react';
import './GameButton.css';

const variantForClass = {
  red: 'game-button_red',
  green: 'game-button_green',
  blue: 'game-button_blue',
};

interface GameButtonProps {
  variant: keyof typeof variantForClass;
  size?: 'sm' | 'md';
  disabled?: boolean;
  children: React.ReactNode;
  onClick: () => void;
  style?: CSSProperties;
  className?: string;
}

export const GameButton = (props: GameButtonProps) => {
  return (
    <div
      className={`game-button_root ${variantForClass[props.variant]} ${
        props.disabled === true ? 'game-button_disabled' : 'game-button_enabled'
      } ${props.className ?? ''}`}
      onClick={props.disabled === true ? undefined : props.onClick}
      style={props.style}
    >
      <div className={'game-button_child-container'}>{props.children}</div>
    </div>
  );
};
