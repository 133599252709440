import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../styles';
import { NavigationButton } from './NavigationItem';

const StyledNavigationPanel = styled(FlexContainer)`
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

interface NavigationPanelProps<T> {
  items: Array<NavigationItem<T>>;
  onClick: (name: T) => void;
  activeTab: T;
}

export interface NavigationItem<T> {
  name: T;
  icon: string;
}

export const NavigationPanel: <T>(props: NavigationPanelProps<T>) => JSX.Element = (props) => {
  const { items, onClick, activeTab } = props;

  return (
    <StyledNavigationPanel>
      {items.map((navigationItem, index) => (
        <NavigationButton
          key={'navigation-button-' + index}
          item={navigationItem}
          isActive={activeTab === navigationItem.name}
          onClick={onClick}
        />
      ))}
    </StyledNavigationPanel>
  );
};
