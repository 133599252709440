import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
`;

export const VerticalFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexItem = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
`;

export const VerticalFlexItem = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

export const Center = styled.div`
  text-align: center;
  margin-bottom: 1em;
`;

export const Unselectable = styled.div`
  user-select: none;
`;

export const StyledButton = styled.button`
  width: 100%;
  font-size: 40px;
`;

export const BigFont = styled.div`
  font-size: 80px;
  text-align: center;
`;

export const MediumFont = styled(Center)`
  font-size: 22px;
`;

export const PageHeaderFont = styled(Center)`
  font-size: 34px;
  font-weight: 500;
`;

export const CARD_TYPES_COLOR_SCHEME = {
  Vehicle: '#EFC969',
  Driver: '#5DCFF3',
  Hat: '#51ECC7',
};

export function getCardTypeColor(cardType: string): string | undefined {
  return CARD_TYPES_COLOR_SCHEME[cardType];
}

export const ColoredContainer = styled(FlexContainer)`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
