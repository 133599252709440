import React, { ReactChild } from 'react';
import { GamePhase, WrecklessBoardProps } from '@magicyard/wreckless-game/src/Types';
import BettingPhase from './BettingPhase';
import DraftingPhaseContainer from '../containers/drafting-phase-container/drafting-phase-container';
import RacerSelectPhase from './RacerSelectPhase';
import RacingPhase, { PendingMove } from './RacingPhase';
import MenuPhase from './MenuPhase';
import './Board.css';
import { ScoringPhase } from './ScoringPhase';
import { TitleBanner } from '@magicyard/shared/components/Banner';
import { GameButton } from './GameButton';
import { assertNever } from '../utils';

const getComponentForPhase = ({
  boardProps,
  isTutorial,
}: {
  isTutorial: boolean | null;
  boardProps: WrecklessBoardProps;
}) => {
  const phase = boardProps.ctx.phase as GamePhase;
  switch (phase) {
    case GamePhase.Drafting:
      return <DraftingPhaseContainer {...boardProps} />;
    case GamePhase.Menu:
      return <MenuPhase boardProps={boardProps} isTutorial={isTutorial} />;
    case GamePhase.WarmUp:
      return undefined;
    case GamePhase.RacerSelect:
      return <RacerSelectPhase {...boardProps} />;
    case GamePhase.Betting:
    case GamePhase.Racing:
      return (
        <RacingPhase
          props={boardProps}
          initialSlide={{
            forceActive:
              phase === GamePhase.Betting
                ? true
                : (
                    boardProps.G.roundPlayerData[boardProps.G.currentRacingRound][boardProps.playerID]?.turnState
                      .pendingMoves || []
                  ).length !== 0,
            render: phase === GamePhase.Betting ? <BettingPhase {...boardProps} /> : <PendingMove {...boardProps} />,
          }}
        />
      );
    case GamePhase.Scoring:
      return <ScoringPhase {...boardProps} />;
    default:
      assertNever(phase);
  }
};

const Board = (props: { boardProps: WrecklessBoardProps; onPlayAgain: () => void; isTutorial: boolean | null }) => {
  const boardProps = props.boardProps;
  console.log(props.boardProps.ctx.phase);

  return (
    <Parent phase={boardProps.ctx.phase as GamePhase}>
      {boardProps.ctx.phase === null ? (
        <NullPhase {...props} />
      ) : (
        getComponentForPhase({
          boardProps: boardProps,
          isTutorial: props.isTutorial,
        })
      )}
    </Parent>
  );
};

const phaseHasCoinsBG: Record<GamePhase, boolean> = {
  betting: true,
  drafting: false,
  menu: false,
  racerSelect: false,
  racing: true,
  scoring: true,
  warmUp: false,
};

const Parent = ({ children }: { phase: GamePhase | null; children: ReactChild }) => {
  return (
    <div className={`board_root`}>
      {phaseHasCoinsBG && <div className={'board_root-coins'} />}
      <div className={'board_root-dark'}>{children}</div>
    </div>
  );
};

const NullPhase = (props: { boardProps: WrecklessBoardProps; onPlayAgain: () => void }) => {
  const boardProps = props.boardProps;
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <TitleBanner colorName={boardProps.G.players[boardProps.playerID].colorName}>
        <></>
      </TitleBanner>
      <div className={'board_play-again'}>Play Again?</div>
      <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <GameButton variant={'green'} onClick={props.onPlayAgain}>
          Play again
        </GameButton>
      </div>
    </div>
  );
};

export default Board;
