import './Card.css';
import React from 'react';
import { DraftItemSpecified } from '@magicyard/wreckless-game/src/Types';
import { AccessoryCardId, AllCardIds, DriverCardID, VehicleCardId } from '@magicyard/wreckless-game/src/getData';
import funny_candles from '../../assets/cards/accessories/FunnyCandles.png';
import outlaw_bandanna from '../../assets/cards/accessories/bandanna.png';
import leaf from '../../assets/cards/accessories/4Leaf.png';
import bindle from '../../assets/cards/accessories/Bindle.png';
import ball from '../../assets/cards/accessories/CrystalBall.png';
import ray from '../../assets/cards/accessories/FreezeRay.png';
import legs from '../../assets/cards/accessories/FrogLegs.png';
import dice from '../../assets/cards/accessories/FuzzyDice.png';
import keychain from '../../assets/cards/accessories/Keychain.png';
import map from '../../assets/cards/accessories/Map.png';
import sprayer from '../../assets/cards/accessories/OilSprayer.png';
import cat from '../../assets/cards/accessories/PetCat.png';
import mask from '../../assets/cards/accessories/PlagueMask.png';
import hat from '../../assets/cards/accessories/PropellerHat.png';
import scarf from '../../assets/cards/accessories/RedScraf.png';
import bouquet from '../../assets/cards/accessories/RoseBouquet.png';
import shovel from '../../assets/cards/accessories/Shovel.png';
import bow from '../../assets/cards/accessories/SilkVest.png';
import spotlight from '../../assets/cards/accessories/Spotlight.png';
import backpack from '../../assets/cards/accessories/GoldenBackpack.png';
import achilles from '../../assets/cards/drivers/Achillies.png';
import adventurer from '../../assets/cards/drivers/Adventurer.png';
import cheerleader from '../../assets/cards/drivers/Cheerleader.png';
import dancer from '../../assets/cards/drivers/Dancer.png';
import duelist from '../../assets/cards/drivers/Duelist.png';
import champion from '../../assets/cards/drivers/ExChampion.png';
import ghost from '../../assets/cards/drivers/Ghost.png';
import gremlin from '../../assets/cards/drivers/Gremlin.png';
import hare from '../../assets/cards/drivers/Hare.png';
import hypnotist from '../../assets/cards/drivers/Hypnotist.png';
import leprechaun from '../../assets/cards/drivers/Leprechaun.png';
import disquise from '../../assets/cards/drivers/MasterOfDisguise.png';
import ogre from '../../assets/cards/drivers/Ogre.png';
import pirate from '../../assets/cards/drivers/Pirate.png';
import x from '../../assets/cards/drivers/RacerX.png';
import referee from '../../assets/cards/drivers/Referee.png';
import robot from '../../assets/cards/drivers/Robot.png';
import girl from '../../assets/cards/drivers/RubberGirl.png';
import shadow from '../../assets/cards/drivers/Shadow.png';
import strategist from '../../assets/cards/drivers/Strategist.png';
import trooper from '../../assets/cards/drivers/Trooper.png';
import god from '../../assets/cards/drivers/WindGod.png';
import craft from '../../assets/cards/vehicles/AirCraft.png';
import ambulance from '../../assets/cards/vehicles/Ambulance.png';
import bumperCar from '../../assets/cards/vehicles/Bumper.png';
import dragon from '../../assets/cards/vehicles/Dragon.png';
import electricCar from '../../assets/cards/vehicles/ElectricCar.png';
import elephant from '../../assets/cards/vehicles/Elephant.png';
import board from '../../assets/cards/vehicles/HoverBoard.png';
import art from '../../assets/cards/vehicles/LogArt.png';
import jetRider from '../../assets/cards/vehicles/JetRide.png';
import mecha from '../../assets/cards/vehicles/Mecha.png';
import mechanical_spider from '../../assets/cards/vehicles/MechaOctopus.png';
import nuclear from '../../assets/cards/vehicles/NuclearCar.png';
import porcupine from '../../assets/cards/vehicles/Porcupine.png';
import wagon from '../../assets/cards/vehicles/RedWagon.png';
import tooter from '../../assets/cards/vehicles/Tooter.png';
import lowRide from '../../assets/cards/vehicles/LowRider.png';
import turtle from '../../assets/cards/vehicles/Turtle.png';
import ufo from '../../assets/cards/vehicles/UFO.png';
import { assertNever } from '../../utils';

const cardIdAccessory: Record<AccessoryCardId, string> = {
  spotlight: spotlight,
  bindle: bindle,
  fuzzy_dice: dice,
  outlaw_bandanna: outlaw_bandanna,
  bow_tie: bow,
  red_scarf: scarf,
  funny_candles: funny_candles,
  crystal_ball: ball,
  freeze_ray: ray,
  keychain: keychain,
  leaf_clover: leaf,
  map: map,
  golden_backpack: backpack,
  pet_cat: cat,
  plague_doctor_hat: mask,
  propeller_hat: hat,
  rose_bouquet: bouquet,
  shovel: shovel,
};

const cardIdDriver: Record<DriverCardID, string> = {
  Ogre: ogre,
  achilles: achilles,
  adventurer: adventurer,
  cheerleader: cheerleader,
  dancer: dancer,
  duelist: duelist,
  ghost: ghost,
  hare: hare,
  leprechaun: leprechaun,
  rubber_girl: girl,
  master_of_disguise: disquise,
  pirate: pirate,
  trooper: trooper,
  robot: robot,
  shadow: shadow,
  strategist: strategist,
  wind_god: god,
  gremlin: gremlin,
};

const cardIdVehicle: Record<VehicleCardId, string> = {
  elephant: elephant,
  hover_board: board,
  nuclear_car: nuclear,
  aircraft: craft,
  jet_car: jetRider,
  ambulance: ambulance,
  bumper_car: bumperCar,
  mechanical_spider: mechanical_spider,
  dragon_mount: dragon,
  electric_car: electricCar,
  log_car: art,
  low_rider: lowRide,
  mecha: mecha,
  porcupine: porcupine,
  red_wagon: wagon,
  tooter: tooter,
  turtle_mount: turtle,
  ufo: ufo,
};

const cardIdToBackground: Record<AllCardIds, string> = { ...cardIdAccessory, ...cardIdDriver, ...cardIdVehicle };

export const Card = ({
  onClick,
  item,
  variant,
  selected,
  disabled,
}: {
  item: DraftItemSpecified;
  variant: 'sm' | 'sm-md' | 'md' | 'md-lg' | 'lg' | 'xlg' | 'xxlg';
  selected?: boolean;
  onClick?: () => void;
  highlight?: boolean;
  disabled?: boolean;
}) => {
  const textAndPos = getTextAndPosition(item);
  return (
    <div
      className={`card_root card_root-${variant} ${disabled === true ? 'card_root-disabled' : ''}`}
      onClick={onClick}
    >
      {/*{selected === true && <div className={'card_highlight'} />}*/}
      <div className={'card_background'} style={{ backgroundImage: `url(${cardIdToBackground[item.ID]})` }} />
      <div className={'card_ribbon-title'}>{item.Name}</div>
      <div className={'card_power-container'}>
        {Array(item.Rarity)
          .fill(0)
          .map((_, i) => {
            return (
              <div className={'card_power-level-container'} key={i + item.ID} style={{ animationDelay: `${i * 0.4}s` }}>
                <div
                  className={`card_power-level card_power-level-active`}
                  style={{ animationDelay: `${i * 0.4 + 2}s` }}
                />
              </div>
            );
          })}
      </div>
      {variant !== 'sm' && variant !== 'md' && variant !== 'md-lg' && variant !== 'sm-md' && (
        <div className={'card_power-txt'}>{item.Power}</div>
      )}
      {textAndPos !== null && <div className={`card_positioned-text-${textAndPos.position}`}>{textAndPos.value}</div>}
    </div>
  );
};

const getTextAndPosition = (item: DraftItemSpecified) => {
  console.log('item: ', item);
  switch (item.Type) {
    case 'Driver':
      return item.Attack > 0 ? { position: 'left-attack', value: item.Attack } : null;
    case 'Vehicle':
      return { position: 'left-life', value: item.Life };
    case 'Hat':
      return null;
    default:
      assertNever(item);
  }
};
