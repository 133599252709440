import React from 'react';
import { DraftItemSpecified } from '@magicyard/wreckless-game/src/Types';
import './DraftingSet.css';
import { Card } from './Card';

interface DraftingSetProps {
  draftingSet: Array<DraftItemSpecified>;
  isDisabled: boolean;
  index: number;
}

export const DraftingSet = ({ draftingSet, index, isDisabled }: DraftingSetProps) => {
  return (
    <div className={`drafting-set_root`}>
      {draftingSet.map((item, i) => (
        <div key={i} className={`drafting-set_item-${i}`} style={{ animationDelay: `${0.15 * index + 0.2}s` }}>
          <Card item={item} variant={'sm-md'} disabled={isDisabled} />
        </div>
      ))}
    </div>
  );
};
