import { useEffect, useState } from 'react';

export function requestFullscreen(): void {
  if (document.fullscreenEnabled) {
    document.documentElement.requestFullscreen().catch((e) => console.log(e));
  }
}

export function exitFullscreen() {
  document.exitFullscreen().catch((e) => console.log(e));
}

const useToggleFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(!(document?.fullscreenElement == null));

  useEffect(() => {
    const evFn = () => {
      setIsFullscreen(!(document.fullscreenElement == null));
    };
    document.addEventListener('fullscreenchange', evFn);
    return () => {
      document.removeEventListener('fullscreenchange', evFn);
    };
  }, []);

  return {
    toggleFullscreen: function () {
      return isFullscreen ? exitFullscreen() : requestFullscreen();
    },
    isFullscreen: isFullscreen,
  };
};

export default useToggleFullscreen;
