import { NAMES_TO_COLORS } from '@magicyard/wreckless-game/src/Game';
import { DriverCardID } from '@magicyard/wreckless-game/src/getData';
import { BetsOnCard, Racer } from '@magicyard/wreckless-game/src/Types';
import { Banner } from '@magicyard/shared/components/Banner';
import React, { useState } from 'react';
import ogre from '../assets/bet/portraits/ogre.png';
import achilles from '../assets/bet/portraits/achilles.png';
import adventurer from '../assets/bet/portraits/adventurer.png';
import cheerleader from '../assets/bet/portraits/cheerleader.png';
import dancer from '../assets/bet/portraits/dancer.png';
import duelist from '../assets/bet/portraits/duelist.png';
import ghost from '../assets/bet/portraits/ghost.png';
import hare from '../assets/bet/portraits/hare.png';
import rubberGirl from '../assets/bet/portraits/rubberGirl.png';
import leprechaun from '../assets/bet/portraits/leprechaun.png';
import masterOfDisguise from '../assets/bet/portraits/masterOfDisguise.png';
import pirate from '../assets/bet/portraits/pirate.png';
import robot from '../assets/bet/portraits/robot.png';
import shadow from '../assets/bet/portraits/shadow.png';
import strategist from '../assets/bet/portraits/strategist.png';
import windGod from '../assets/bet/portraits/windGod.png';
import gremlin from '../assets/bet/portraits/gremlin.png';
import trooper from '../assets/bet/portraits/trooper.png';
import blue from '../assets/bet/backgrounds/blue.png';
import green from '../assets/bet/backgrounds/green.png';
import yellow from '../assets/bet/backgrounds/yellow.png';
import pink from '../assets/bet/backgrounds/pink.png';
import purple from '../assets/bet/backgrounds/purple.png';
import red from '../assets/bet/backgrounds/red.png';
import './BettingPhaseRow.css';
import { CardOverlay, draftItemToSimpleDraftItemWithHandId } from './card/CardOverlay';

const idToPortrait: Record<DriverCardID, string> = {
  Ogre: ogre,
  achilles: achilles,
  adventurer: adventurer,
  cheerleader: cheerleader,
  dancer: dancer,
  duelist: duelist,
  trooper: trooper,
  ghost: ghost,
  rubber_girl: rubberGirl,
  hare: hare,
  leprechaun: leprechaun,
  master_of_disguise: masterOfDisguise,
  pirate: pirate,
  robot: robot,
  shadow: shadow,
  strategist: strategist,
  wind_god: windGod,
  gremlin: gremlin,
};

const colorToBackground: Record<keyof typeof NAMES_TO_COLORS, string> = {
  blue: blue,
  green: green,
  orange: yellow,
  pink: pink,
  purple: purple,
  red: red,
};

interface BettingPhaseRowProps {
  colorName: keyof typeof NAMES_TO_COLORS;
  racer: Racer;
  name: string;
  bet?: BetsOnCard;
  isSelected: boolean;
  onClick: () => void;
  disabled?: { click: boolean; color: boolean };
  btnText: string;
  withOverlay: boolean;
  showChip: boolean;
}

export const BettingPhaseRow = ({
  bet,
  colorName,
  racer,
  name,
  isSelected,
  onClick,
  disabled,
  btnText,
  withOverlay,
  showChip,
}: BettingPhaseRowProps) => {
  const withHandId = draftItemToSimpleDraftItemWithHandId([racer.driver, racer.vehicle, racer.hat]);
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <>
      {showOverlay && (
        <CardOverlay
          current={withHandId[0]}
          all={withHandId}
          onOverlayClick={() => setShowOverlay(false)}
          submit={{ action: onClick, buttonText: btnText }}
          header={
            <div>
              <BettingPhaseRowBanner
                name={name + "'s cards"}
                isSelected={true}
                colorName={colorName}
                racer={racer}
                showChip={showChip}
              />
              {bet !== undefined && (
                <div className={'betting-phase-row_right-container-overlay'}>
                  <BettingPhaseRowRight bet={bet} />
                </div>
              )}
            </div>
          }
        />
      )}
      <div
        className={`betting-phase-row_root ${disabled?.color ? 'betting-phase-row_disabled' : ''}`}
        onClick={disabled?.click !== true ? (withOverlay ? () => setShowOverlay(true) : onClick) : undefined}
      >
        <BettingPhaseRowBanner
          name={name}
          isSelected={isSelected}
          colorName={colorName}
          racer={racer}
          showChip={showChip}
        />
        {bet !== undefined && (
          <div className={'betting-phase-row_right-container'}>
            <BettingPhaseRowRight bet={bet} />
          </div>
        )}
      </div>
    </>
  );
};

const BettingPhaseRowRight = ({ bet }: { bet: BetsOnCard }) => {
  return (
    <div className={'betting-phase-row_right'}>
      <div className={'betting-phase-row_coin'} />
      <div>{bet.betCard.reward}</div>
      /
      <div className={'betting-phase-row_coin'} />
      <div>{Math.floor(bet.betCard.reward / 2)}</div>
    </div>
  );
};

const BettingPhaseRowBanner = ({
  colorName,
  isSelected,
  racer,
  name,
  showChip,
}: {
  name: string;
  isSelected: boolean;
  colorName: keyof typeof NAMES_TO_COLORS;
  racer: Racer;
  showChip: boolean;
}) => {
  return (
    <>
      <Banner size={'sm'} colorName={colorName} style={{ position: 'absolute' }} partial={true} />
      <div className={'betting-phase-row_left'}>
        {isSelected && showChip && <div className={'betting-phase-row_chip'} />}
        <div
          className={'betting-phase-row_driver-bg'}
          style={{ backgroundImage: `url(${colorToBackground[colorName]})` }}
        >
          <div
            style={{ backgroundImage: `url(${idToPortrait[racer.driver.ID]})` }}
            className={'betting-phase-row_driver'}
          />
        </div>
        <div>{name}</div>
      </div>
    </>
  );
};
