import React, { useEffect, useState } from 'react';
import { BidPanel, colorToBtn } from '../../../components/BidPanel';
import { GameState } from '@magicyard/wreckless-game/src/Types';
import { DraftingSet } from '../../../components/card/DraftingSet';
import './BetSummary.css';
import { PlayerID } from 'boardgame.io';
import { NAMES_TO_COLORS } from '@magicyard/wreckless-game/src/Game';

export const BetSummary = (props: { G: GameState; playerID: PlayerID; moves: any; isActive: boolean }) => {
  const { currentSetIndex, players, tokensByPlayerID, bidsByRoundBySetIndex, draftingSets, draftingIndex } = props.G;
  const { playerID } = props;
  const currentPlayerTokens = tokensByPlayerID?.[playerID];
  const currentPlayer = players?.[playerID];
  const [animationKey, setAnimationKey] = useState(-1);
  useEffect(() => {
    // Animate ONE time per drafting index, and ONLY when first active
    if (draftingIndex !== animationKey && props.isActive) {
      setAnimationKey(draftingIndex);
    }
  }, [props.isActive, draftingIndex, animationKey]);

  const getMinimumBid = (setIndex: number | null) => {
    if (setIndex === null) {
      return 0;
    }
    const currentBid = bidsByRoundBySetIndex[draftingIndex][setIndex];
    return currentBid !== null ? currentBid.tokens + 1 : 0;
  };
  return (
    <div className={'bet-summary_root-container'}>
      <div className={'bet-summary_root'}>
        <div className={'bet-summary_grid-container'}>
          <div className={'bet-summary_grid'}>
            {draftingSets[draftingIndex]?.map((draftingSet, setIndex) => {
              const existingBid = bidsByRoundBySetIndex?.[draftingIndex]?.[setIndex];

              const currentPlayerBid = existingBid && players ? players[existingBid.playerID] : undefined;
              const isSelected = setIndex === currentSetIndex;
              return (
                <div
                  key={`set-${setIndex}${animationKey}`}
                  className={`bet-summary_draft-container ${isSelected ? 'bet-summary_draft-container-selected' : ''}`}
                  onClick={() => {
                    if (isSelected) {
                      props.moves.setCurrentSetIndex(null);
                    } else {
                      props.moves.setCurrentSetIndex(setIndex);
                    }
                  }}
                  style={{ animationDelay: `${setIndex * 0.1}s` }}
                >
                  <DraftingSet
                    draftingSet={draftingSet}
                    index={setIndex}
                    isDisabled={currentSetIndex !== null && currentSetIndex !== setIndex}
                  />
                  {currentPlayerBid ? (
                    <BidIcon
                      key={setIndex}
                      tokens={existingBid.tokens}
                      colorName={currentPlayerBid.colorName}
                      animationDelay={`${setIndex * 0.2}s`}
                    />
                  ) : undefined}
                </div>
              );
            })}
          </div>
        </div>
        <div className={'bet-summary_bid-container'}>
          <BidPanel
            min={getMinimumBid(currentSetIndex)}
            max={currentPlayerTokens}
            onClick={(tokens) => {
              props.moves.bid(tokens);
            }}
            disabled={currentSetIndex === null}
            colorName={currentPlayer.colorName}
          />
        </div>
      </div>
    </div>
  );
};

export const BidIcon = ({
  colorName,
  animationDelay,
  tokens,
}: {
  colorName: keyof typeof NAMES_TO_COLORS;
  animationDelay?: string;
  tokens: number;
}) => {
  return (
    <div
      className={'bet-summary_active-bid'}
      style={{
        backgroundImage: `url(${colorToBtn[colorName]})`,
        animationDelay: animationDelay,
      }}
    >
      <div className={'bet-summary_active-bid-text'}>{tokens}</div>
    </div>
  );
};
