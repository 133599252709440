import React, { ReactChild, useState } from 'react';
import { NAMES_TO_COLORS } from '@magicyard/wreckless-game/src/Game';
import { AllCardIds, DRIVERS_CARDS, HATS_CARDS, VEHICLES_CARDS } from '@magicyard/wreckless-game/src/getData';
import { CardPart } from './card/CardPart';
import { GameButton } from './GameButton';
import { PlayerData, PlayerInfo } from '@magicyard/wreckless-game/src/Types';
import { BettingPhaseRow } from './BettingPhaseRow';
import './RacingPhaseMove.css';

const getMarginFromCardId = (cardId: AllCardIds | null) => {
  if (DRIVERS_CARDS.some((x) => x.ID === cardId)) {
    return { marginBottom: '2vh', marginTop: '-5vh' };
  } else if (HATS_CARDS.some((x) => x.ID === cardId)) {
    return { marginBottom: '5vh', marginTop: '5vh' };
  } else if (VEHICLES_CARDS.some((x) => x.ID === cardId)) {
    return { marginBottom: '5vh', marginTop: '5vh' };
  }
  return { marginBottom: 0, marginTop: 0 };
};

export const Container = ({
  title,
  children,
  colorName,
  cardId,
}: {
  title: string;
  children: ReactChild | ReactChild[];
  colorName: keyof typeof NAMES_TO_COLORS;
  cardId: AllCardIds | null;
}) => {
  return (
    <div className={'racing-phase-move_container-root'} style={{ backgroundColor: NAMES_TO_COLORS[colorName] }}>
      <div className={'racing-phase-move_background'} />
      {cardId !== null && <CardPart variant={'lg'} cardId={cardId} />}
      <div
        style={{
          ...getMarginFromCardId(cardId),
          color: 'white',
          fontSize: '4vh',
          textAlign: 'center',
          width: '80%',
          zIndex: 1,
        }}
      >
        {title}
      </div>
      {children}
    </div>
  );
};

export const NumberSelection = ({
  onSelect,
  numbers,
}: {
  onSelect: (selection: number) => void;
  numbers: number[];
}) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {numbers.map((n) => (
        <GameButton variant={'blue'} onClick={() => onSelect(n)} key={n}>
          {n}
        </GameButton>
      ))}
    </div>
  );
};

export const SelectPlayer = ({
  onClick,
  players,
}: {
  onClick: (n: number) => void;
  players: { info: PlayerInfo; data: PlayerData }[];
}) => {
  const [selected, setSelected] = useState<number | undefined>(undefined);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '100%', alignSelf: 'flex-start' }}>
        {players.map((p) => (
          <BettingPhaseRow
            showChip={false}
            key={p.data.playerID}
            onClick={() => setSelected((s) => (s === p.data.playerID ? undefined : p.data.playerID))}
            colorName={p.info.colorName}
            isSelected={selected === p.data.playerID}
            name={p.info.name}
            racer={p.data.racer}
            btnText={'Select racer'}
            withOverlay={false}
            disabled={{ color: selected !== undefined && selected !== p.data.playerID, click: false }}
          />
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
          marginBottom: '10vh',
          marginTop: '2vh',
        }}
      >
        <GameButton variant={'red'} onClick={() => onClick(-1)}>
          Skip
        </GameButton>
        <GameButton variant={'green'} onClick={() => onClick(selected)} disabled={selected === undefined}>
          Activate
        </GameButton>
      </div>
    </div>
  );
};

export const YesNo = ({ onClick }: { onClick: (val: boolean) => void }) => {
  return (
    <div
      style={{
        display: 'flex',
        bottom: '10vh',
        position: 'absolute',
        justifyContent: 'space-around',
        width: '100%',
      }}
    >
      <GameButton variant={'red'} onClick={() => onClick(false)}>
        Skip
      </GameButton>
      <GameButton variant={'green'} onClick={() => onClick(true)}>
        Activate
      </GameButton>
    </div>
  );
};

export const Roll = ({ onClick }: { onClick: (val: boolean) => void }) => {
  return (
    <div style={{ display: 'flex' }}>
      <GameButton variant={'green'} onClick={() => onClick(true)}>
        Roll!
      </GameButton>
    </div>
  );
};
