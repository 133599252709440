import React from 'react';
import { WrecklessBoardProps, ScoreUpdate, ScoreUpdateCategory } from '@magicyard/wreckless-game/src/Types';
import { TitleBanner } from '@magicyard/shared/components/Banner';
import './ScoringPhase.css';
import { Card } from './card/Card';

const animUnit = 's';

const enterAnimationDuration = 0.8 + animUnit;
const enterAnimationDelay = 0.6;

const animationStyleForDelay = (delay: number) => {
  return { animationDuration: enterAnimationDuration, animationDelay: enterAnimationDelay * delay + animUnit };
};

const positionToMedal = (position: number) => {
  if (position === 1) {
    return 'gold';
  }
  if (position === 2) {
    return 'bronze';
  }
  if (position === 3) {
    return 'metal';
  }
  return 'broken';
};

export const ScoringPhase = (props: WrecklessBoardProps) => {
  const data = props.G.roundPlayerData[props.G.currentRacingRound][props.playerID];
  const playerScoreByCategory = props.G.scoreUpdatesByRound[props.G.currentRacingRound].reduce<
    Record<ScoreUpdateCategory, ScoreUpdate[]>
  >(
    (acc, x) => {
      if (x.playerID === props.playerID) {
        acc[x.category].push(x);
      }
      return acc;
    },
    {
      Bet: [],
      Card: [],
      Race: [],
    }
  );
  return (
    <>
      <TitleBanner colorName={props.G.players[props.playerID].colorName}>Results</TitleBanner>
      <div className={'scoring-phase_root'}>
        <div className={'scoring-phase_score-title'}>Your score</div>
        <div className={'scoring-phase_score'}>
          <div className={'scoring-phase_coin'} />
          {props.G.scoreByPlayerID[props.playerID]}
        </div>

        <div className={'scoring-phase_body'}>
          <div className={'scoring-phase_chips'} style={animationStyleForDelay(0)} />
          <div className={'scoring-phase_number-container'} style={animationStyleForDelay(1)}>
            <div className={'scoring-phase_coin-sm'} />
            {playerScoreByCategory.Bet.reduce((sum, x) => sum + x.score, 0)}
          </div>

          <div className={'scoring-phase_cards-container'} style={animationStyleForDelay(2)}>
            <div className={'scoring-phase_card scoring-phase_card-first'}>
              <Card item={data.racer.driver} variant={'sm'} />
            </div>
            <div className={'scoring-phase_card'}>
              <Card item={data.racer.vehicle} variant={'sm'} />
            </div>
            <div className={'scoring-phase_card-last'}>
              <Card item={data.racer.hat} variant={'sm'} />
            </div>
          </div>
          <div className={'scoring-phase_number-container'} style={animationStyleForDelay(3)}>
            <div className={'scoring-phase_coin-sm'} />
            {playerScoreByCategory.Card.reduce((sum, x) => sum + x.score, 0)}
          </div>
          <div
            className={`scoring-phase_medal scoring-phase_medal-${positionToMedal(data.placeFinished)}`}
            style={animationStyleForDelay(4)}
          >
            {data.placeFinished}
          </div>
          <div className={'scoring-phase_number-container'} style={animationStyleForDelay(5)}>
            <div className={'scoring-phase_coin-sm'} />
            {playerScoreByCategory.Race.reduce((sum, x) => sum + x.score, 0)}
          </div>
        </div>
      </div>
    </>
  );
};
