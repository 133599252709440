import { WrecklessBoardProps } from '@magicyard/wreckless-game/src/Types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavigationPanel } from '../../components/navigation-panel/NavigationPanel';
import { BetSummary } from './components/BetSummary';
import { CardsSummary } from './components/CardsSummary';
import BetIcon from '../../assets/tab/bets.png';
import CardsIcon from '../../assets/tab/cards.png';
import SwipeableViews from 'react-swipeable-views';
import { TitleBanner } from '@magicyard/shared/components/Banner';

const NavigationPanelContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const DraftingPhaseContainer = (props: WrecklessBoardProps) => {
  const [activeTab, setActiveTab] = useState<'Bet' | 'My Cards'>('Bet');
  const { currentPlayer: currentTurnPlayerID } = props.ctx;
  const { playerID: currentPlayerID } = props;

  const handleNavigationItemClick = (name: 'Bet' | 'My Cards') => {
    if (currentPlayerID !== currentTurnPlayerID) {
      return;
    }
    setActiveTab(name);
  };

  useEffect(() => {
    if (currentPlayerID === currentTurnPlayerID) {
      setActiveTab('Bet');
    }
  }, [currentPlayerID, currentTurnPlayerID]);

  const computedActiveTab = currentTurnPlayerID !== currentPlayerID ? 'My Cards' : activeTab;

  return (
    <>
      <TitleBanner colorName={props.G.players[props.playerID].colorName}>
        {computedActiveTab === 'Bet' ? 'Bid for cards' : 'Your selected cards'}
      </TitleBanner>
      <SwipeableViews
        enableMouseEvents={true}
        onChangeIndex={(i) => handleNavigationItemClick(i === 0 ? 'Bet' : 'My Cards')}
        index={computedActiveTab === 'Bet' ? 0 : 1}
        disabled={currentTurnPlayerID !== currentPlayerID}
      >
        <BetSummary G={props.G} playerID={props.playerID} moves={props.moves} isActive={computedActiveTab === 'Bet'} />
        <CardsSummary {...props} />
      </SwipeableViews>
      <NavigationPanelContainer>
        <NavigationPanel<'Bet' | 'My Cards'>
          items={[
            { name: 'Bet', icon: BetIcon },
            { name: 'My Cards', icon: CardsIcon },
          ]}
          onClick={handleNavigationItemClick}
          activeTab={computedActiveTab}
        />
      </NavigationPanelContainer>
    </>
  );
};

export default DraftingPhaseContainer;
