import { objectKeys } from '@magicyard/wreckless-game/src/utils';
import { Card } from './Card';
import React, { CSSProperties, Fragment, useState } from 'react';
import { BidType, DraftItems, DraftItemSpecified } from '@magicyard/wreckless-game/src/Types';
import { DraftItemWithHandId } from '../RacerSelectPhase';
import './CardGrid.css';
import { CardOverlay } from './CardOverlay';
import { undo } from 'boardgame.io/dist/types/src/core/action-creators';
import { colorToBtn } from '../BidPanel';
import { NAMES_TO_COLORS } from '@magicyard/wreckless-game/src/Game';
import { BidIcon } from '../../containers/drafting-phase-container/components/BetSummary';

export const CardGrid = ({
  hand,
  style,
  className,
  selectedItems,
  onSubmit,
  variant,
  bidItems,
}: {
  bidItems?: { bid: BidType & { colorName: keyof typeof NAMES_TO_COLORS }; items: Array<DraftItemSpecified> };
  selectedItems?: Record<DraftItems, DraftItemWithHandId | undefined>;
  hand: Array<DraftItemSpecified>;
  variant?: 'sm' | 'md' | 'md-lg';
  style?: CSSProperties;
  className?: string;
  onSubmit?: (selection: DraftItemWithHandId) => void;
}) => {
  const [currentHighlight, setCurrentHighlight] = useState<DraftItemWithHandId | undefined>(undefined);
  const handWithIds = hand.map((item, handId) => ({ ...item, handId }));
  const itemByType = handWithIds.reduce<Record<DraftItems, DraftItemWithHandId[]>>(
    (acc, item) => {
      acc[item.Type].push(item);
      return acc;
    },
    { Driver: [], Vehicle: [], Hat: [] }
  );

  const bidItemByType = bidItems?.items.reduce(
    (acc, item) => {
      acc[item.Type].push(item);

      return acc;
    },
    {
      Driver: [],
      Vehicle: [],
      Hat: [],
    }
  );
  console.log(itemByType);
  const handleSubmit =
    onSubmit === undefined
      ? undefined
      : (selection) => {
          onSubmit(selection);
          setCurrentHighlight(undefined);
        };
  return (
    <div className={`card-grid_root ${className ?? ''}`} style={style}>
      {objectKeys(itemByType).map((type, typeIndex) => {
        return (
          <Fragment key={typeIndex}>
            {itemByType[type].map((item, i) => (
              <div style={{ gridArea: `${typeIndex + 1} / ${i + 1}` }} key={i}>
                <Card
                  disabled={
                    selectedItems !== undefined &&
                    selectedItems[type] !== undefined &&
                    selectedItems[type]?.handId !== item.handId
                  }
                  item={item}
                  variant={variant ?? 'md-lg'}
                  onClick={() => setCurrentHighlight(item)}
                />
              </div>
            ))}
            {bidItemByType !== undefined &&
              bidItemByType[type]?.map((item, i) => (
                <Fragment key={i}>
                  <div
                    style={{
                      gridArea: `${typeIndex + 1} / ${itemByType[type].length + 1 + i}`,
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    key={i}
                  >
                    <Card disabled={true} item={item} variant={variant ?? 'md-lg'} />
                    <BidIcon
                      colorName={bidItems.bid.colorName}
                      tokens={bidItems.bid.tokens}
                      animationDelay={`${0.3 * (i + typeIndex)}s`}
                    />
                  </div>
                </Fragment>
              ))}
          </Fragment>
        );
      })}
      {currentHighlight !== undefined ? (
        <CardOverlay
          current={currentHighlight}
          all={itemByType[currentHighlight.Type]}
          onOverlayClick={() => setCurrentHighlight(undefined)}
          submit={handleSubmit === undefined ? undefined : { action: handleSubmit, buttonText: 'Select card' }}
        />
      ) : null}
    </div>
  );
};
