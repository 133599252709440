import React, { useState } from 'react';
import { Client } from 'boardgame.io/react';
import Board from './components/Board';
import { WRECKLESS } from '@magicyard/wreckless-game/src/Game';
import { getMultiplayerMode } from '@magicyard/utils';
import { EffectsBoardWrapper } from 'bgio-effects/react';
import { usePlatformController } from '@magicyard/shared/platform/hooks/usePlatformController';
import { GameButton } from './components/GameButton';
import { TitleBanner } from '@magicyard/shared/components/Banner';
import { Profile } from '@magicyard/shared/platform/lib/api';
import './App.css';
import { OrientationMessage } from './OrientationMessage';
import useToggleFullscreen from '@magicyard/shared/platform/hooks/useToggleFullscreen';
import { YardWithDisplayController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';

const IS_DEBUG = false;
const FORCE_NAME_CHANGE = 'Entering name...';
const WrappedBoard = (onStartAgain: (gameId: string) => void, isTutorial: boolean) =>
  EffectsBoardWrapper(
    (G) => <Board boardProps={G} isTutorial={isTutorial} onPlayAgain={() => onStartAgain('wreckless')} />,
    {
      updateStateAfterEffects: true,
    }
  );

export const App = () => {
  const query = new URLSearchParams(window.location.search);
  const [isTutorial, setIsTutorial] = useState(localStorage.getItem('tutorial') !== 'false');
  const x = usePlatformController(
    {
      onInitialLoading() {
        return <div>Loading</div>;
      },
      onGame({controller, onGameEnd, onStartAgain, onStartAgainOnline}) {
        const urlParams = new URLSearchParams(new URL((controller.gameStartArgs as { url: string }).url).search);
        const playerID = urlParams.get('playerID');
        const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';
        const serverURL: string | undefined = urlParams.get('serverURL') ?? undefined;

        const C = Client({
          game: WRECKLESS,
          board: WrappedBoard(onStartAgain, isTutorial),
          debug: IS_DEBUG,
          multiplayer: getMultiplayerMode(serverURL),
        });
        return (
          <>
            <OrientationMessage />
            <C playerID={playerID} matchID={matchID} />
          </>
        );
      },
      onGameLoading() {
        return <div>Loading</div>;
      },

      onInvalidYard({controller, onDisplayScanned, onDisplayCodeEntered}) {
        return <InvalidYard onSubmit={onDisplayCodeEntered} />;
      },
      onOnlineQueue() {
        return undefined;
      },
      onYard() {
        return undefined;
      },
      onYardWithDisplay({controller, onProfileUpdate, onSubmitOnline, onSubmitLocal}) {
        const handleSubmit = (isTutorial: boolean) => {
          setIsTutorial(isTutorial);
          onSubmitLocal();
        };
        return (
          <YardWithDisplay
            controller={controller}
            onSubmit={handleSubmit}
            onProfileUpdate={onProfileUpdate}
            defaultIsTutorial={isTutorial}
          />
        );
      },
    },
    { displayId: query.get('displayId'), yardId: query.get('yardId') },
    `https://avatars.dicebear.com/api/croodles/${Math.random()}.svg`,
    FORCE_NAME_CHANGE
  );

  return (
    <div className={'app_root'}>
      <BurgerMenu />
      {x}
    </div>
  );
};

const InvalidYard = (props: { onSubmit: (code) => void }) => {
  const [code, setCode] = useState('');
  return (
    <div className={'app_invalid-yard-container'}>
      <div className={'app_invalid-yard-title'}>Enter room code</div>
      <input
        onChange={(e) => setCode((last) => (e.target.value.toString().length > 4 ? last : e.target.value))}
        maxLength={4}
        className={'app_invalid-yard-input'}
        type={'number'}
        inputMode={'numeric'}
        value={code}
        autoFocus={true}
      />
      <GameButton
        className={'app_invalid-yard-btn'}
        variant={'green'}
        onClick={() => props.onSubmit(code)}
        disabled={code.length !== 4}
      >
        Submit
      </GameButton>
    </div>
  );
};

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { toggleFullscreen } = useToggleFullscreen();
  return (
    <>
      <div className={'app_burger-menu'} onClick={() => setIsOpen(true)} />
      <div
        className={`app_burger-menu-root ${isOpen ? '' : 'app_burger-menu-root-closed'}`}
        onClick={() => setIsOpen(false)}
      >
        <div className={`app_burger-menu-bg ${isOpen ? '' : 'app_burger-menu-bg-closed'}`}></div>
        <div className={`app_burger-menu-container ${isOpen ? 'app_burger-menu-container-open' : ''}`}>
          <div onClick={() => toggleFullscreen()} className={'app_burger-menu-item'}>
            <div className={'app_burger-menu-item-fullscreen'} />
            Full screen
          </div>
        </div>
      </div>
    </>
  );
};

const YardWithDisplay = ({
  controller,
  onSubmit,
  onProfileUpdate,
  defaultIsTutorial,
}: {
  defaultIsTutorial: boolean;
  onSubmit: (isTutorial: boolean) => void;
  controller: YardWithDisplayController;
  onProfileUpdate: (update: Partial<Omit<Profile, 'id'>>) => Promise<void>;
}) => {
  const [edit, setEdit] = useState<null | string>(controller.profile.name === FORCE_NAME_CHANGE ? '' : null);
  const [isTutorial, setIsTutorial] = useState(defaultIsTutorial);
  const [didSubmit, setDidSubmit] = useState(false);
  const toggleTutorialState = () => {
    setIsTutorial((val) => {
      localStorage.setItem('tutorial', '' + !val);
      return !val;
    });
  };

  const handleSubmit = (val: string) => {
    if (val.length > 1) {
      onProfileUpdate({ name: val });
      setEdit(null);
    }
  };

  return (
    <>
      <div style={{ width: '100vw' }}>
        <TitleBanner colorName={'purple'}>
          <>
            {edit === null ? (
              <div style={{ width: '50vw' }}>{controller.profile.name}</div>
            ) : (
              <input
                placeholder={'Choose name'}
                autoFocus={true}
                className={'app_yard-with-display-input'}
                value={edit}
                onChange={(e) => setEdit(e.target.value)}
                onSubmit={(e) => {
                  handleSubmit(edit);
                }}
              />
            )}
            <div
              onClick={() => (edit === null ? setEdit(controller.profile.name) : undefined)}
              className={'app_yard-and-display-pen'}
            />
          </>
        </TitleBanner>
        {edit !== null && (
          <div className={'app_yard-and-display-edit'}>
            <GameButton variant={'green'} onClick={() => handleSubmit(edit)}>
              Confirm
            </GameButton>
          </div>
        )}
      </div>
      {edit !== null ? null : !didSubmit ? (
        <div
          style={{
            height: '100%',
            width: '100vw',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginBottom: '5vh' }}>
            <GameButton variant={'blue'} onClick={() => setDidSubmit(true)} style={{ marginBottom: '2vh' }}>
              Start game
            </GameButton>
            <GameButton variant={'green'} onClick={toggleTutorialState}>
              <div className={'app_yard-and-display-tutorial-btn'}>
                <div className={'app_yard-and-display-tutorial-check-container'}>
                  <div
                    className={`app_yard-and-display-tutorial-check app_yard-and-display-tutorial-check-${
                      isTutorial ? 'on' : 'off'
                    }`}
                  />
                </div>
                Play tutorial
              </div>
            </GameButton>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: '100%',
            width: '100vw',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginBottom: '5vh' }}>
            <GameButton
              variant={'green'}
              onClick={() => onSubmit(isTutorial)}
              style={{ marginBottom: '2vh', fontSize: '3vh' }}
              key={'everyones here'}
            >
              Everyone's here!
            </GameButton>
            <GameButton variant={'red'} onClick={() => setDidSubmit(false)} key={'not yet'}>
              Not yet
            </GameButton>
          </div>
        </div>
      )}
    </>
  );
};
