import React from 'react';
import './NavigationItem.css';
import { NavigationItem } from './NavigationPanel';

export interface NavigationButtonProps<T> {
  item: NavigationItem<T>;
  isActive: boolean;
  onClick: (name: T) => void;
}

export const NavigationButton: <T>(props: NavigationButtonProps<T>) => JSX.Element = (props) => {
  const { item, isActive } = props;
  const { onClick } = props;
  return (
    <div
      onClick={() => onClick(props.item.name)}
      className={`navigation-item_root ${isActive ? '' : ' navigation-item_root-inactive'}`}
    >
      <div
        className={`navigation-item_icon ${isActive ? 'navigation-item_icon-active' : ''}`}
        style={{ backgroundImage: `url(${item.icon})` }}
      />
    </div>
  );
};
