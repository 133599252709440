import React, { CSSProperties } from 'react';
import './CardPart.css';

import leaf from '../../assets/cards/parts/accesseories/4leaf.png';
import bindle from '../../assets/cards/parts/accesseories/bindle.png';
import bowTie from '../../assets/cards/parts/accesseories/bowTie.png';
import crystalBall from '../../assets/cards/parts/accesseories/crystalBall.png';
import freezeRay from '../../assets/cards/parts/accesseories/freezeRay.png';
import frogLegs from '../../assets/cards/parts/accesseories/frogLegs.png';
import funnyCandles from '../../assets/cards/parts/accesseories/funnyCandles.png';
import fuzzyDice from '../../assets/cards/parts/accesseories/fuzzyDice.png';
import keychain from '../../assets/cards/parts/accesseories/keychain.png';
import map from '../../assets/cards/parts/accesseories/map.png';
import oilSprayer from '../../assets/cards/parts/accesseories/oilSprayer.png';
import petCat from '../../assets/cards/parts/accesseories/petCat.png';
import plagueMask from '../../assets/cards/parts/accesseories/plagueMask.png';
import propellerHat from '../../assets/cards/parts/accesseories/propellerHat.png';
import redScarf from '../../assets/cards/parts/accesseories/redScarf.png';
import roseBouquet from '../../assets/cards/parts/accesseories/roseBouquet.png';
import shovel from '../../assets/cards/parts/accesseories/shovel.png';
import spotlight from '../../assets/cards/parts/accesseories/spotlight.png';
import backpack from '../../assets/cards/parts/accesseories/spotlight.png';
import aircraft from '../../assets/cards/parts/vehicles/aircraft.png';
import ambulance from '../../assets/cards/parts/vehicles/ambulance.png';
import bumperCar from '../../assets/cards/parts/vehicles/bumperCar.png';
import dragon from '../../assets/cards/parts/vehicles/dragon.png';
import electric from '../../assets/cards/parts/vehicles/electricCar.png';
import elephant from '../../assets/cards/parts/vehicles/elephant.png';
import hoverboard from '../../assets/cards/parts/vehicles/hoverboard.png';
import jetRide from '../../assets/cards/parts/vehicles/jetRide.png';
import logCar from '../../assets/cards/parts/vehicles/logCar.png';
import lowRider from '../../assets/cards/parts/vehicles/lowRider.png';
import mecha from '../../assets/cards/parts/vehicles/mecha.png';
import mechanical_spider from '../../assets/cards/parts/vehicles/mechaSpider.png';
import nuclearCar from '../../assets/cards/parts/vehicles/nuclearCar.png';
import porcupine from '../../assets/cards/parts/vehicles/porcupine.png';
import redWagon from '../../assets/cards/parts/vehicles/redWagon.png';
import tooter from '../../assets/cards/parts/vehicles/tooter.png';
import turtle from '../../assets/cards/parts/vehicles/turtle.png';
import ufo from '../../assets/cards/parts/vehicles/ufo.png';
import { AccessoryCardId, AllCardIds, DriverCardID, VehicleCardId } from '@magicyard/wreckless-game/src/getData';

import duelist from '../../assets/cards/parts/drivers/duelist.png';
import adventurer from '../../assets/cards/parts/drivers/adventurer.png';
import cheerleader from '../../assets/cards/parts/drivers/cheerleader.png';
import dancer from '../../assets/cards/parts/drivers/dancer.png';
import gremlin from '../../assets/cards/parts/drivers/germlin.png';
import achilles from '../../assets/cards/parts/drivers/achilles.png';
import ghost from '../../assets/cards/parts/drivers/ghost.png';
import hare from '../../assets/cards/parts/drivers/hare.png';
import hypnotist from '../../assets/cards/parts/drivers/hypnotist.png';
import leprechaun from '../../assets/cards/parts/drivers/leprechaun.png';
import masterOfDisguise from '../../assets/cards/parts/drivers/masterOfDisguise.png';
import ogre from '../../assets/cards/parts/drivers/ogre.png';
import pirate from '../../assets/cards/parts/drivers/pirate.png';
import racerX from '../../assets/cards/parts/drivers/racerX.png';
import referee from '../../assets/cards/parts/drivers/referee.png';
import robot from '../../assets/cards/parts/drivers/robot.png';
import rubberGirl from '../../assets/cards/parts/drivers/rubberGirl.png';
import shadow from '../../assets/cards/parts/drivers/shadow.png';
import strategist from '../../assets/cards/parts/drivers/strategist.png';
import trooper from '../../assets/cards/parts/drivers/trooper.png';
import windGod from '../../assets/cards/parts/drivers/windGod.png';

const cardIdAccessory: Record<AccessoryCardId, string> = {
  spotlight: spotlight,
  bindle: bindle,
  golden_backpack: backpack,
  outlaw_bandanna: frogLegs,
  red_scarf: redScarf,
  fuzzy_dice: fuzzyDice,
  funny_candles: funnyCandles,
  bow_tie: bowTie,
  crystal_ball: crystalBall,
  freeze_ray: freezeRay,
  keychain: keychain,
  leaf_clover: leaf,
  map: map,
  pet_cat: petCat,
  plague_doctor_hat: plagueMask,
  propeller_hat: propellerHat,
  rose_bouquet: roseBouquet,
  shovel: shovel,
};

const cardIdDriver: Record<DriverCardID, string> = {
  Ogre: ogre,
  achilles: achilles, // TODO missing image
  adventurer: adventurer,
  cheerleader: cheerleader,
  dancer: dancer,
  duelist: duelist,
  ghost: ghost,
  hare: hare,
  rubber_girl: rubberGirl,
  leprechaun: leprechaun,
  master_of_disguise: masterOfDisguise,
  pirate: pirate,
  robot: robot,
  shadow: shadow,
  strategist: strategist,
  wind_god: windGod,
  trooper: trooper,
  gremlin: gremlin,
};

const cardIdVehicle: Record<VehicleCardId, string> = {
  hover_board: hoverboard,
  elephant: elephant,
  nuclear_car: nuclearCar,
  aircraft: aircraft,
  mechanical_spider: mechanical_spider,
  ambulance: ambulance,
  bumper_car: bumperCar,
  dragon_mount: dragon,
  electric_car: electric,
  log_car: logCar,
  low_rider: lowRider,
  jet_car: jetRide,
  mecha: mecha,
  porcupine: porcupine,
  red_wagon: redWagon,
  tooter: tooter,
  turtle_mount: turtle,
  ufo: ufo,
};

const allCardParts = { ...cardIdAccessory, ...cardIdVehicle, ...cardIdDriver };

export const CardPart = ({
  cardId,
  variant,
  style,
  className,
}: {
  style?: CSSProperties;
  className?: string;
  cardId: AllCardIds;
  variant: 'sm' | 'md' | 'lg';
}) => (
  <div
    className={`card-part_root card-part_${variant} ${className ?? ''}`}
    style={{ backgroundImage: `url(${allCardParts[cardId]})`, ...style }}
  >
    <div className={`card-part_shade card-part_${variant}`} />
  </div>
);
