import React, { useEffect, useState } from 'react';
import { PlayerData, PlayerInfo, PossibleMove, WrecklessBoardProps } from '@magicyard/wreckless-game/src/Types';

import { SelectedRacerDisplay } from './RacerSelectPhase';
import { TitleBanner } from '@magicyard/shared/components/Banner';
import { GameButton } from './GameButton';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { assertNever } from '../utils';
import { Container, NumberSelection, Roll, SelectPlayer, YesNo } from './RacingPhaseMove';
import './RacingPhase.css';
import DiceIcon from '../assets/tab/dice.png';
import CardsIcon from '../assets/tab/cards.png';
import { NavigationPanel } from './navigation-panel/NavigationPanel';
import { Swiper as SwiperClass } from 'swiper/types';
import { Pagination } from 'swiper';
import { PaginationOptions } from 'swiper/types/modules/pagination';

export const PendingMove = (props: WrecklessBoardProps) => {
  const move: PossibleMove =
    props.G.roundPlayerData[props.G.currentRacingRound][props.playerID]?.turnState.pendingMoves[0];
  const moves = props.moves;
  const colorName = props.G.players[props.playerID].colorName;
  const oneToSix = [1, 2, 3, 4, 5, 6];
  if (move === undefined) {
    return (
      <Container title={'No pending actions...'} colorName={colorName} cardId={null}>
        <div></div>
      </Container>
    );
  }

  switch (move) {
    case 'pickALuckyNumberMove':
      return (
        <Container title={'Pick a lucky number'} colorName={colorName} cardId={'leaf_clover'}>
          <NumberSelection numbers={oneToSix} onSelect={moves.pickALuckyNumberMove} />
        </Container>
      );
    case 'pickACrystalBallMove':
      return (
        <Container title={'Which place will you finish?'} colorName={colorName} cardId={'crystal_ball'}>
          <NumberSelection onSelect={props.moves.pickACrystalBallMove} numbers={oneToSix} />
          <GameButton variant={'red'} onClick={() => props.moves.pickACrystalBallMove(-1)}>
            Not finish
          </GameButton>
        </Container>
      );
    case 'fleetFootedMove':
      return (
        <Container
          title={'Would you to lose 1 life for changing the die to 5?'}
          colorName={colorName}
          cardId={'achilles'}
        >
          <YesNo onClick={props.moves.fleetFootedMove} />
        </Container>
      );
    case 'shortcutMove':
      return (
        <Container title={'Change your roll to 6?'} colorName={colorName} cardId={'map'}>
          <YesNo onClick={props.moves.shortcutMove} />
        </Container>
      );
    case 'jetCarMove':
      return (
        <Container
          title={'Lose 2 life to triple your die result, after use, -1 speed permanent? (Usable Once)'}
          colorName={colorName}
          cardId={'jet_car'}
        >
          <YesNo onClick={props.moves.jetCarMove} />
        </Container>
      );
    case 'experienceChargeMove':
      return (
        <Container
          title={'Would you like to skip this turn and gain a permanent +2 movement?'}
          colorName={colorName}
          cardId={'adventurer'}
        >
          <YesNo onClick={props.moves.experienceChargeMove} />
        </Container>
      );
    case 'planMove':
      return (
        <Container title={'Would you like to change your roll?'} colorName={colorName} cardId={'strategist'}>
          <NumberSelection
            onSelect={props.moves.planMove}
            numbers={Array(
              props.G.roundPlayerData[props.G.currentRacingRound][props.ctx.currentPlayer].turnState.dieResult - 1
            )
              .fill(0)
              .map((_, i) => i + 1)}
          />
          <GameButton variant={'red'} onClick={() => props.moves.planMove(-1)}>
            Nope
          </GameButton>
        </Container>
      );
    case 'feedTheLeprechaunMove':
      return (
        <Container
          cardId={'leprechaun'}
          title={'Would you like to roll the die again? (Leprechaun will get +3 next turn)'}
          colorName={colorName}
        >
          <YesNo onClick={props.moves.feedTheLeprechaunMove} />
        </Container>
      );
    case 'rubberGirlMove':
      return (
        <Container
          cardId={'rubber_girl'}
          title={'Would you like to lose 1 life for and roll the die again?'}
          colorName={colorName}
        >
          <YesNo onClick={props.moves.rubberGirlMove} />
        </Container>
      );
    case 'fuzzyDiceMove':
      return (
        <Container title={'Would you like to change your roll?'} colorName={colorName} cardId={'fuzzy_dice'}>
          <NumberSelection
            onSelect={props.moves.fuzzyDiceMove}
            numbers={[
              props.G.roundPlayerData[props.G.currentRacingRound][props.ctx.currentPlayer].turnState.dieResult - 1,
              props.G.roundPlayerData[props.G.currentRacingRound][props.ctx.currentPlayer].turnState.dieResult + 1,
            ]}
          />
          <GameButton variant={'red'} onClick={() => props.moves.fuzzyDiceMove(-1)}>
            Nope
          </GameButton>
        </Container>
      );
    case 'shadowMove':
      const { G } = props;
      const { playerID } = props;
      const playersData = G.roundPlayerData[G.currentRacingRound];
      const currentPlayerData = G.roundPlayerData[G.currentRacingRound][playerID];
      const playersInfo: Array<PlayerInfo> = G.players;
      const { triggeredOnPlayerID } = currentPlayerData.actionAttributes.shadow;
      const triggeredOnPlayer = playersData[triggeredOnPlayerID];
      return (
        <Container title={'Shadow'} colorName={colorName} cardId={'shadow'}>
          {`Would you like to move to ${playersInfo?.[triggeredOnPlayer.playerID].name}'s location(${
            triggeredOnPlayer.locationOnMap
          })?`}
          <YesNo onClick={props.moves.shadowMove} />
        </Container>
      );
    case 'towMove': {
      const { players } = props.G;
      const playerLocation = props.G.roundPlayerData[props.G.currentRacingRound][props.playerID].locationOnMap;
      const playersInSameLocation = props.G.roundPlayerData[props.G.currentRacingRound]
        .filter((playerData) => playerData.locationOnMap === playerLocation)
        .map((playerData) => Number(playerData.playerID))
        .filter((playerID) => playerID !== Number(props.playerID));

      const finalPlayers = players.reduce<{ info: PlayerInfo; data: PlayerData }[]>((acc, playerInfo, index) => {
        if (playersInSameLocation.includes(index)) {
          acc.push({ info: playerInfo, data: props.G.roundPlayerData[props.G.currentRacingRound][index] });
        }
        return acc;
      }, []);
      return (
        <Container title={'Which player do you want to tow?'} colorName={colorName} cardId={'red_wagon'}>
          <SelectPlayer onClick={props.moves.towMove} players={finalPlayers} />
        </Container>
      );
    }
    case 'elephantMove': {
      const { players } = props.G;
      const playerLocation = props.G.roundPlayerData[props.G.currentRacingRound][props.playerID].locationOnMap;
      const playersInSameLocation = props.G.roundPlayerData[props.G.currentRacingRound]
        .filter((playerData) => playerData.locationOnMap === playerLocation)
        .map((playerData) => Number(playerData.playerID))
        .filter((playerID) => playerID !== Number(props.playerID));

      const finalPlayers = players.reduce<{ info: PlayerInfo; data: PlayerData }[]>((acc, playerInfo, index) => {
        if (playersInSameLocation.includes(index)) {
          acc.push({ info: playerInfo, data: props.G.roundPlayerData[props.G.currentRacingRound][index] });
        }
        return acc;
      }, []);

      return (
        <Container
          title={'Which player would you like to stun (cost 1 life)?'}
          colorName={colorName}
          cardId={'elephant'}
        >
          <SelectPlayer onClick={props.moves.elephantMove} players={finalPlayers} />
        </Container>
      );
    }
    case 'spiderMove': {
      const { players } = props.G;
      const playerLocation = props.G.roundPlayerData[props.G.currentRacingRound][props.playerID].locationOnMap;
      const playersInSameLocation = props.G.roundPlayerData[props.G.currentRacingRound]
        .filter((playerData) => playerData.locationOnMap === playerLocation)
        .map((playerData) => Number(playerData.playerID))
        .filter((playerID) => playerID !== Number(props.playerID));

      const finalPlayers = players.reduce<{ info: PlayerInfo; data: PlayerData }[]>((acc, playerInfo, index) => {
        if (playersInSameLocation.includes(index)) {
          acc.push({ info: playerInfo, data: props.G.roundPlayerData[props.G.currentRacingRound][index] });
        }
        return acc;
      }, []);

      return (
        <Container
          title={'Which player would you like to slow (cost 1 life)?'}
          colorName={colorName}
          cardId={'mechanical_spider'}
        >
          <SelectPlayer onClick={props.moves.spiderMove} players={finalPlayers} />
        </Container>
      );
    }
    case 'swordDanceMove':
      return (
        <Container title={'Shall we dance?'} colorName={colorName} cardId={'dancer'}>
          <YesNo onClick={props.moves.swordDanceMove} />
        </Container>
      );
    case 'rollDieMove':
      return (
        <Container title={'Roll?'} colorName={colorName} cardId={null}>
          <Roll onClick={props.moves.rollDieMove} />
        </Container>
      );
    case 'rerollDieMove':
      return (
        <Container title={'Reroll?'} colorName={colorName} cardId={null}>
          <YesNo onClick={props.moves.rerollDieMove} />
        </Container>
      );
    case 'masterOfDisguiseMove': {
      const { G } = props;
      const { players } = G;
      const playersData = G.roundPlayerData[G.currentRacingRound];
      const eligiblePlayersData: Array<number> = playersData
        .filter((playersData) => playersData.placeFinished === null)
        .map((playerData) => playerData.playerID);
      const finalPlayers = players.reduce<{ info: PlayerInfo; data: PlayerData }[]>((acc, playerInfo, index) => {
        if (eligiblePlayersData.includes(index) && index !== Number(props.playerID)) {
          acc.push({ info: playerInfo, data: props.G.roundPlayerData[props.G.currentRacingRound][index] });
        }
        return acc;
      }, []);
      return (
        <Container title={'Replace position with player?'} colorName={colorName} cardId={'master_of_disguise'}>
          <SelectPlayer onClick={props.moves.masterOfDisguiseMove} players={finalPlayers} />
        </Container>
      );
    }
    default:
      assertNever(move);
  }
};

const RacingPhase = ({
  props,
  initialSlide,
}: {
  props: WrecklessBoardProps;
  initialSlide: { forceActive: boolean; render: React.ReactChild };
}) => {
  const [_, forceStateUpdate] = useState<'Cards' | 'Action'>(initialSlide.forceActive ? 'Cards' : 'Action');
  const swiperRef = React.useRef<SwiperClass | null>(null);

  useEffect(() => {
    // Bug with swiper not sending stateUpdate after index change..
    swiperRef.current?.on('activeIndexChange', (swiper) =>
      forceStateUpdate(swiper.activeIndex === 0 ? 'Action' : 'Cards')
    );
  }, []);

  // When new moves come in immediately switch to action
  useEffect(() => {
    if (initialSlide.forceActive) {
      swiperRef.current?.slideTo(0);
    } else {
      swiperRef.current?.slideTo(+props.playerID + 1);
    }
  }, [initialSlide.forceActive, props.playerID]);

  const handleNavigationItemClick = (name: 'Action' | 'Cards') => {
    if (name === 'Action') {
      swiperRef.current?.slideTo(0);
    } else {
      swiperRef.current?.slideTo(1);
    }
  };
  const pagination: PaginationOptions = {
    clickable: true,
    type: 'custom',
    renderCustom: (swiper, current: number, total: number) => {
      let toReturn = ``;
      for (let i = 0; i < total; i++) {
        if (i === 0) {
          toReturn += `<div class='racing-phase_first ${current === 1 ? 'racing-phase_dot-active' : ''}'></div>`;
        } else {
          toReturn += `<div class='racing-phase_dot ${current === i + 1 ? 'racing-phase_dot-active' : ''}'></div>`;
        }
      }
      return `<div class='racing-phase_pagination'>${toReturn}<div>`;
    },
  };

  return (
    <>
      <Swiper
        initialSlide={initialSlide.forceActive ? 0 : +props.playerID + 1}
        onSwiper={(s) => (swiperRef.current = s)}
        pagination={pagination}
        modules={[Pagination]}
      >
        <SwiperSlide style={{ overflow: 'hidden' }}>{initialSlide.render}</SwiperSlide>
        {props.G.players.map((p, i) => {
          const roundData = props.G.roundPlayerData[props.G.currentRacingRound][i];
          return (
            <SwiperSlide key={i}>
              <TitleBanner colorName={props.G.players[i].colorName}>
                {`${props.G.players[i].name} ${+props.playerID === i ? '(You)' : ''}`}
              </TitleBanner>
              <SelectedRacerDisplay
                selected={{
                  Driver: { ...roundData.racer.driver, handId: 2 },
                  Vehicle: { ...roundData.racer.vehicle, handId: 0 },
                  Hat: { ...roundData.racer.hat, handId: 1 },
                }}
                attack={roundData.attackPower}
                life={roundData.life}
                coins={props.G.scoreByPlayerID[i]}
                isDamaged={roundData.life !== roundData.racer.vehicle.Life}
              />
            </SwiperSlide>
          );
        })}
        <div style={{ position: 'absolute', bottom: 0, width: '100%', zIndex: 1 }}>
          <NavigationPanel<'Action' | 'Cards'>
            items={[
              { name: 'Action', icon: DiceIcon },
              { name: 'Cards', icon: CardsIcon },
            ]}
            onClick={(name) => handleNavigationItemClick(name)}
            activeTab={swiperRef.current?.activeIndex === 0 ? 'Action' : 'Cards'}
          />
        </div>
      </Swiper>
    </>
  );
};
const ArrowNext = () => {
  const swiper = useSwiper();
  return <div onClick={() => swiper.slideNext()} className={'racing-phase_next'} />;
};

const ArrowPrev = () => {
  const swiper = useSwiper();
  return <div className={'racing-phase_prev'} onClick={() => swiper.slidePrev()} />;
};

export default RacingPhase;
