import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from './styles';
import './BidPanel.css';
import BidBtnBlue from '../assets/bid/bid-btn-blue.png';
import BidBtnGreen from '../assets/bid/bid-btn-green.png';
import BidBtnOrange from '../assets/bid/bid-btn-orange.png';
import BidBtnPink from '../assets/bid/bid-btn-pink.png';
import BidBtnPurple from '../assets/bid/bid-btn-purple.png';
import BidBtnRed from '../assets/bid/bid-btn-red.png';
import { NAMES_TO_COLORS } from '@magicyard/wreckless-game/src/Game';

export const colorToBtn: Record<keyof typeof NAMES_TO_COLORS, string> = {
  blue: BidBtnBlue,
  green: BidBtnGreen,
  orange: BidBtnOrange,
  pink: BidBtnPink,
  purple: BidBtnPurple,
  red: BidBtnRed,
};

export const ButtonsContainer = styled(FlexContainer)`
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const BidSectionContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export interface BidPanelProps {
  min: number;
  max: number;
  colorName: keyof typeof NAMES_TO_COLORS;
  onClick: (bid: number) => void;
  disabled: boolean;
}

export const BidPanel = (props: BidPanelProps) => {
  const { min, max, onClick, disabled, colorName } = props;
  const [currentBid, setCurrentBid] = useState(0);

  const handleIncreaseBid = () => {
    if (disabled) {
      return;
    }
    setCurrentBid((c) => Math.min(max, c + 1));
  };

  const handleDecreaseBid = () => {
    if (disabled) {
      return;
    }
    setCurrentBid((c) => Math.max(0, c - 1));
  };

  const canCurrentlyBid = min === 0 || currentBid >= min;

  return (
    <BidSectionContainer>
      <ButtonsContainer>
        <div
          onClick={handleDecreaseBid}
          className={`bid-panel_minus ${
            disabled || currentBid === 0 ? 'bid-panel_btn-disabled' : 'bid-panel_btn-shine'
          }`}
        />
        <div
          className={`bid-panel_bid-btn-bottom`}
          onClick={() => {
            if (canCurrentlyBid) {
              onClick(currentBid);
              setCurrentBid(0);
            }
          }}
        >
          <div
            style={{ backgroundImage: `url(${colorToBtn[colorName]})` }}
            className={`bid-panel_bid-btn ${
              disabled || !canCurrentlyBid ? 'bid-panel_btn-disabled' : 'bid-panel_btn-shine'
            }`}
          >
            <div className={'bid-panel_bid-btn-text'}>{currentBid}</div>
          </div>
          <div className={'bid-panel_btn-bottom-txt'}>
            {currentBid} / {max}
          </div>
        </div>
        <div
          onClick={handleIncreaseBid}
          className={`bid-panel_plus ${
            disabled || currentBid === max ? 'bid-panel_btn-disabled' : 'bid-panel_btn-shine'
          }`}
        />
      </ButtonsContainer>
    </BidSectionContainer>
  );
};
