import React from 'react';
import './OrientationMessage.css';
import landscapeToPortrait from './assets/landscapeToPortrait.webp';

export const OrientationMessage = (): JSX.Element => {
  const getSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  const [windowSize, setWindowSize] = React.useState(getSize);

  React.useLayoutEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (windowSize.width > windowSize.height) {
    return (
      <div className={'OrientationMessage_root'}>
        <div>Wreckless is played in Portrait mode</div>
        <div
          className={'OrientationMessage_iconContainer'}
          style={{ backgroundImage: `url(${landscapeToPortrait})` }}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
