import React, { useState } from 'react';
import { WrecklessBoardProps } from '@magicyard/wreckless-game/src/Types';
import './BettingPhase.css';

import { BettingPhaseRow } from './BettingPhaseRow';
import { GameButton } from './GameButton';
import { TitleBanner } from '@magicyard/shared/components/Banner';

const BettingPhase = (props: WrecklessBoardProps) => {
  const currentBet = props.G.betsTable.findIndex((betsOnCard) => {
    return betsOnCard.bettors.includes(props.playerID);
  });
  const [selected, setSelected] = useState<number>(currentBet);
  const finishedBetting = currentBet !== -1;

  return (
    <div className={'betting-phase_root'}>
      <TitleBanner colorName={props.G.players[props.playerID].colorName}>
        {finishedBetting ? 'May the odds be with you' : 'Place your bets'}
      </TitleBanner>
      <div className={'betting-phase_header'}>
        <div className={'betting-phase_header-medal-gold'}>1</div>/
        <div className={'betting-phase_header-medal-bronze'}>2</div>
      </div>
      {props.G.betsTable.map((betsOnCard, i) => {
        const player = props.G.players[betsOnCard.betCard.racer];
        return (
          <BettingPhaseRow
            showChip={true}
            key={i}
            isSelected={selected === i}
            onClick={() => setSelected((s) => (s === i ? -1 : i))}
            btnText={'Select racer'}
            colorName={player.colorName}
            racer={props.G.roundPlayerData[props.G.currentRacingRound][betsOnCard.betCard.racer].racer}
            name={player.name}
            bet={betsOnCard}
            disabled={{ color: selected !== -1 && selected !== i, click: finishedBetting }}
            withOverlay={false}
          />
        );
      })}
      <div className={'betting-phase_btn'}>
        <GameButton
          variant={'blue'}
          disabled={selected === -1 || finishedBetting}
          onClick={() => {
            props.moves.bet(selected);
          }}
        >
          Place bet
        </GameButton>
      </div>
    </div>
  );
};
export default BettingPhase;
