import React, { useState } from 'react';
import { Card } from './Card';
import './CardOverlay.css';
import { GameButton } from '../GameButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DraftItemWithHandId } from '../RacerSelectPhase';
import 'swiper/swiper-bundle.min.css';
import { EffectCards } from 'swiper';
import ReactDOM from 'react-dom';
import { DraftItemSpecified, DraftItemType } from '@magicyard/wreckless-game/src/Types';

interface CardOverlayProps {
  current: DraftItemWithHandId;
  all: DraftItemWithHandId[];
  onOverlayClick?: () => void;
  submit?: { action: (selection: DraftItemWithHandId) => void; buttonText: string };
  header?: React.ReactChild;
}

export const draftItemToSimpleDraftItemWithHandId = (cards: DraftItemSpecified[]): DraftItemWithHandId[] => {
  return cards.map((c, i) => ({ ...c, handId: i }));
};

export const CardOverlay = ({ current, all, onOverlayClick, submit, header }: CardOverlayProps) => {
  const [activeIndex, setCurrentActiveIndex] = useState(all.findIndex((x) => x.handId === current.handId));
  const body = (
    <div className={'card-overlay_root'} onClick={onOverlayClick}>
      {header}
      <div className={'card-overlay_body'}>
        <Swiper
          effect={'cards'}
          slidesPerView={'auto'}
          style={{ width: '40vh' }}
          onActiveIndexChange={(i) => setCurrentActiveIndex(i.activeIndex)}
          initialSlide={activeIndex}
          modules={[EffectCards]}
          grabCursor={true}
        >
          {all.map((card, i) => (
            <SwiperSlide onClick={(e) => e.stopPropagation()} key={i}>
              <div
                className={'card-overlay_card-container'}
                style={{ animationDelay: Math.abs(activeIndex - i) * 0.2 + 's' }}
              >
                <Card
                  item={card}
                  variant={'xxlg'}
                  onClick={() => setCurrentActiveIndex(i)}
                  highlight={activeIndex === i}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {submit !== undefined && (
        <div className={'card-overlay_footer'}>
          <GameButton onClick={() => submit.action(all[activeIndex])} variant={'green'} style={{ marginTop: '6vh' }}>
            {submit.buttonText}
          </GameButton>
        </div>
      )}
    </div>
  );
  return ReactDOM.createPortal(body, window.document.getElementById('root'));
};
