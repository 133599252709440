import React, { useEffect } from 'react';
import { GameMode, WrecklessBoardProps, RollConfing } from '@magicyard/wreckless-game/src/Types';

const Option = ({ title, onClick }) => {
  return (
    <button onClick={onClick}>
      <h2>{title}</h2>
    </button>
  );
};

const MenuPhase = (props: { isTutorial: boolean | null; boardProps: WrecklessBoardProps }) => {
  const selectTutorial = () => props.boardProps.moves.selectGameType({ gameMode: GameMode.Tutorial });
  const selectFullGame = () => props.boardProps.moves.selectGameType({ gameMode: GameMode.FullGame });
  const selectManualRoll = () => props.boardProps.moves.selectGameType({ rollConfig: RollConfing.ManualRoll });
  const selectManualReRoll = () => props.boardProps.moves.selectGameType({ reRoll: true });

  useEffect(() => {
    if (props.isTutorial !== null) {
      if (props.isTutorial) {
        selectTutorial();
      } else {
        selectFullGame();
      }
    }
  }, []);

  return (
    <>
      {props.boardProps.isActive && props.isTutorial === null && (
        <div style={{ zIndex: 100, position: 'relative' }}>
          <h1>Select game mode</h1>
          <Option title="Tutorial" onClick={selectTutorial} />
          <Option title="Full game" onClick={selectFullGame} />
          <Option title="Manual Roll" onClick={selectManualRoll} />
          <Option title="Reroll" onClick={selectManualReRoll} />
        </div>
      )}
    </>
  );
};

export default MenuPhase;
