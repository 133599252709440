import {
  DraftItemDriverType,
  DraftItems,
  DraftItemSpecified,
  DraftItemVehicleType,
  WrecklessBoardProps,
} from '@magicyard/wreckless-game/src/Types';
import React, { useState } from 'react';
import { TitleBanner } from '@magicyard/shared/components/Banner';
import { NAMES_TO_COLORS } from '@magicyard/wreckless-game/src/Game';
import { Card } from './card/Card';
import { objectKeys } from '@magicyard/wreckless-game/src/utils';
import './RacerSelectPhase.css';
import { GameButton } from './GameButton';
import { CardGrid } from './card/CardGrid';
import { CardOverlay } from './card/CardOverlay';

interface SelectItemProps {
  hand: Array<DraftItemSpecified>;
  onSubmit: (items: Record<DraftItems, DraftItemWithHandId>) => void;
  colorName: keyof typeof NAMES_TO_COLORS;

  coins: number;
  isDone: boolean;
}

export type DraftItemWithHandId = DraftItemSpecified & { handId: number };

export const SelectItem = ({ hand, onSubmit, colorName, isDone, coins }: SelectItemProps) => {
  const getInitialState = (): { selected: Record<DraftItems, DraftItemWithHandId | undefined>; didSubmit: boolean } => {
    if (hand.length > 3) {
      return {
        selected: {
          Driver: undefined,
          Vehicle: undefined,
          Hat: undefined,
        },
        didSubmit: false,
      };
    }

    return {
      selected: {
        Driver: { ...hand.find((x) => x.Type === 'Driver'), handId: hand.findIndex((x) => x.Type === 'Driver') },
        Vehicle: { ...hand.find((x) => x.Type === 'Vehicle'), handId: hand.findIndex((x) => x.Type === 'Vehicle') },
        Hat: { ...hand.find((x) => x.Type === 'Hat'), handId: hand.findIndex((x) => x.Type === 'Hat') },
      },
      didSubmit: true,
    };
  };

  const initial = getInitialState();
  const [selectedItems, setSelectedItems] = useState(initial.selected);
  const [didSubmit, setDidSubmit] = useState(initial.didSubmit);
  const canSubmit = objectKeys(selectedItems).every((key) => selectedItems[key] !== undefined);

  const handleSubmit = () => {
    if (canSubmit) {
      setDidSubmit(true);
    }
  };

  if ((didSubmit || isDone) && objectKeys(selectedItems).every((x) => x !== undefined)) {
    return (
      <>
        <TitleBanner colorName={colorName}>
          <>Your {initial.didSubmit ? 'Final' : ''} Racer</>
        </TitleBanner>
        <SelectedRacerDisplay
          selected={selectedItems}
          life={(selectedItems.Vehicle as DraftItemVehicleType).Life}
          attack={(selectedItems.Driver as DraftItemDriverType).Attack}
          isDamaged={false}
          coins={coins}
        />
        {!isDone && (
          <div className={'select-racer-display_button-container'}>
            {!initial.didSubmit && (
              <GameButton variant={'red'} onClick={() => setDidSubmit(false)}>
                Cancel
              </GameButton>
            )}
            <GameButton variant={'blue'} onClick={() => onSubmit(selectedItems)}>
              Confirm
            </GameButton>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <TitleBanner colorName={colorName}>Build your racer</TitleBanner>
      <CardGrid
        className={'racer-select-phase_grid'}
        hand={hand}
        selectedItems={selectedItems}
        onSubmit={(item) => {
          setSelectedItems((old) => ({ ...old, [item.Type]: item }));
        }}
      />
      <div className={'racer-select-phase_btn-container'}>
        <GameButton onClick={handleSubmit} variant={'green'} disabled={!canSubmit}>
          Submit
        </GameButton>
      </div>
    </>
  );
};

const RacerSelectPhase = (props: WrecklessBoardProps) => {
  const done = !!props.G.roundPlayerData[props.G.currentRacingRound][props.playerID];

  const hand = props.G.hands[props.playerID];
  return (
    <SelectItem
      coins={props.G.scoreByPlayerID[props.playerID]}
      hand={hand}
      isDone={done}
      onSubmit={(val) => {
        props.moves.selectRacer(val.Driver.handId, val.Vehicle.handId, val.Hat.handId);
      }}
      colorName={props.G.players[props.playerID].colorName}
    />
  );
};

interface SelectedRacerDisplayProps {
  selected: Record<DraftItems, DraftItemWithHandId>;
  life: number;
  attack: number;
  coins: number;
  isDamaged: boolean;
}

export const SelectedRacerDisplay = ({ selected, life, attack, isDamaged, coins }: SelectedRacerDisplayProps) => {
  const [showOverlay, setShowOverlay] = useState<DraftItems | undefined>(undefined);

  return (
    <>
      <div className={'select-racer-display_stats-container'}>
        <div className={'select-racer-display_gem-container'}>
          <div className={isDamaged ? 'select-racer-display_red-gem-damaged' : 'select-racer-display_red-gem'} />
          <div className={(isDamaged ? 'select-racer-display_gem-text-dmg ' : '') + 'select-racer-display_gem-text'}>
            {life}
          </div>
        </div>
        <div className={'select-racer-display_gem-container'}>
          <div className={'select-racer-display_purple-gem'} />
          <div className={'select-racer-display_gem-text'}>{attack}</div>
        </div>
        <div className={'select-racer-display_gem-container'}>
          <div className={'select-racer-display_coin'} />
          <div className={'select-racer-display_gem-text'}>{coins}</div>
        </div>
      </div>
      {objectKeys(selected).map((key) => (
        <div key={key} className={'select-racer-display_cards-container'}>
          <div style={{ marginRight: '2vh' }}>
            <Card variant={'md-lg'} item={selected[key]} onClick={() => setShowOverlay(key)} />
          </div>
          <div style={{ color: 'white' }}>{selected[key].Power}</div>
        </div>
      ))}
      {showOverlay !== undefined && (
        <CardOverlay
          current={selected[showOverlay]}
          all={[selected.Driver, selected.Vehicle, selected.Hat]}
          onOverlayClick={() => setShowOverlay(undefined)}
        />
      )}
    </>
  );
};

export default RacerSelectPhase;
